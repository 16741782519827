import React, { useState, useEffect, useContext } from 'react';
import { Grid, Card, Box, Avatar, Dialog, DialogTitle, DialogActions, IconButton, TextField, Backdrop, CircularProgress, Typography, Tooltip, Modal } from '@mui/material';
import EvButton from '@components/EvButton';
import DialogC from '@components/Dialog';
import serviceCycles from '@services/serviceCycles';
import serviceCycleJourneys from '@services/serviceCycleJourneys';
import serviceCycleJourneysParticipants from '@services/serviceCycleJourneysParticipants';
import serviceEvaluatorData from '@services/serviceEvaluatorData';
import serviceIncidents from '@services/serviceIncidents'
import { useParams } from 'react-router-dom';
import { Article, ArrowBack, Camera, CameraAlt, Check, Dangerous, Info, ReadMore } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Loading from '@components/Loading';
import 'moment/locale/es';
import { useTranslation } from 'react-i18next';
import getCurrentJourney from '@helpers/currentJourney';
import getNextJourney from '@helpers/nextJourney';
import Button from '@mui/material/Button';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import Fallback from '@assets/images/avatar.png'
import { useStyles } from '@styles/consultants.style'
import serviceFiles from '@services/serviceFiles'
import { snackActions } from '@helpers/snackbarUtils';
import { AuthContext } from '@context/auth/AuthContext'
import serviceParticipantsTargets from '@services/serviceParticipantsTargets'
import serviceParticipants from '@services/serviceParticipants';

const Journey = () => {
  const { t } = useTranslation();
  const { idCycle } = useParams();
  const [loading, setLoading] = useState(false);
  const [cycle, setCycle] = useState(null);
  const [journey, setJourney] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [paratroopers, setParatroopers] = useState([]);
  const [invited, setInvited] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [totalAssistants , setTotalAssistants] = useState([]);
  const [participantSelected , setParticipantSelected] = useState(null)
  const [openDialog, setOpenDialog] = useState(false);
  const [openRemarks, setOpenRemarks] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEvaluatorResults, setOpenEvaluatorResults] = useState(false);
  const [openEvOptions, setOpenEvOptions] = useState(false);
  const [journeyRemarks, setJourneyRemarks] = useState("");
  const [idParticipant, setIdParticipant] = useState(null);
  const [idEvaluatorParticipant, setIdEvaluatorParticipant] = useState(null);
  const [idCycleJourneysParticipants, setIdCycleJourneysParticipants] = useState(null);
  const [reloadKey, setReloadKey] = useState(Math.random())
  const [threeDaysBeforeEnd, setThreeDaysBeforeEnd] = useState(Math.random())
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [evCompleteState, setEvCompleteState] = useState('')
  // const [dataTableResults, setDataTableResults] = useState([])
  const classes = useStyles()
  const [openLoading, setOpenLoading] = useState(false);
  const [extraTimeLimit, setExtraTimeLimit] = useState();
  const [openDialogIncidence, setOpenDialogIncidence] = useState(false)
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [incidences, setIncidences] = useState([]);
  const [incidenceSelected, setIncidenceSelected] = useState(null);
  const { user } = useContext(AuthContext)
  const [openParticipantRemark, setOpenParticipantRemark] = useState(false);
  const [currentParticipantRemark, setCurrentParticipantRemark] = useState('');
  const [cycleParticipantRemark, setCycleParticipantRemark] = useState('');
  const [forceUpdate, setForceUpdate] = useState(0);
  // const [journeysNotThisCycleData, setJourneysNotThisCycleData] = useState({});
  const [participantImages, setParticipantImages] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchParticipantImage = async (idParticipant) => {
    if (!participantImages[idParticipant]) {
        try {
            const { data, error } = await serviceParticipants.getParticipantImage(idParticipant);
            if (!error && data) {
                setParticipantImages(prev => ({
                    ...prev,
                    [idParticipant]: `data:image/png;base64,${data}`
                }));
            }
        } catch (error) {
            console.error(`Error al cargar la imagen del participante ${idParticipant}:`, error);
        }
    }
  };

  useEffect(() => {
        totalAssistants.forEach(participant => {
            fetchParticipantImage(participant.idParticipant);
        });
  }, [totalAssistants]);

    const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '350px',
    bgcolor: '#FFFF99',  // Fondo amarillo
    //border: '1px solid #000',
    boxShadow: 10,
    p: 3
  };

  useEffect(() => {
    fetchCycleDetails(idCycle);
  }, []);

  useEffect(() => {
    fetchIncidences();
  }, [forceUpdate]);

  useEffect(() => {
    journeyRemarksCreate();    
  }, [journey]);

  useEffect(() => {
    let participantsArray = [];  
    let paratroopersArray = [];
    let invitedArray = [];
    let deleted = []

    if (cycle?.cycleParticipants?.length > 0) {
      participantsArray = cycle?.cycleParticipants.filter(x => x.isDeleted === false);
    }

    if (cycle?.cycleParticipants?.length > 0) {  // Así no aparecerían los eliminados o los trasladados a otro grupo
      deleted = cycle?.cycleParticipants?.filter(x => x.isDeleted === true);
    }

    if (cycle?.cycleJourneys?.length > 0) {
      cycle?.cycleJourneys?.find(cycleJourney => cycleJourney.cycleJourneyNumber === journey?.cycleJourneyNumber)?.cycleJourneyParticipants.forEach(cycleJourneyParticipant => {        
        if (!cycle?.cycleParticipants?.some(participant => participant.idParticipant === cycleJourneyParticipant.idParticipant) && cycleJourneyParticipant.idCycleOrigin === null) {          
          invitedArray.push(cycleJourneyParticipant);
        } else if (!cycle?.cycleParticipants?.some(participant => participant.idParticipant === cycleJourneyParticipant.idParticipant) && cycleJourneyParticipant.idCycleOrigin !== null) {
          paratroopersArray.push(cycleJourneyParticipant);
        } 
        //  else if 
      })
    }
    // console.log('invitedArray', invitedArray);
    // console.log('paratroopersArray', paratroopersArray);    
    // console.log('participantsArray', participantsArray);
    
    setParticipants(participantsArray.length > 1 ? organizeJourneys(participantsArray, cycle?.cycleJourneys) : participantsArray)  
    setParatroopers(paratroopersArray.length > 1 ? organizeJourneys(paratroopersArray, cycle?.cycleJourneys) : paratroopersArray)
    setInvited(invitedArray.length > 1 ? organizeJourneys(invitedArray, cycle?.cycleJourneys): invitedArray)    
    setDeleted(deleted.length > 1 ? organizeJourneys(deleted): deleted)   
  }, [cycle, journey])

  useEffect(() => {    
    const nonParticipants = mergeAssistants(paratroopers, invited);
    const nonPartPlusDeleted = mergeAssistants(nonParticipants, deleted);
    const total = mergeAssistants(participants, nonPartPlusDeleted);
    setTotalAssistants(total);
  }, [participants, paratroopers, invited, deleted])


  useEffect(() => {
    if (participantSelected !== null) {
      const newExtraTimeLimit = participantSelected.extraTimeLimit || 15; // 15 días por defecto de ampliación del medidor
      setExtraTimeLimit(newExtraTimeLimit)
    }
  }, [participantSelected])

  const fetchCycleDetails = async(idCycle) => {
    const { data, error } = await serviceCycles.getCycleDetail(idCycle, true, false);
    if (!error) {
      setCycle(data);
      setJourney(getCurrentJourney(data));
      setLoading(false);

        //const lastJourney = data.cycleJourneys.find((journey, i) => i === data.cycleJourneys.length - 1)
        const newDate = new Date(data.evaluatorDateEnd);

        setThreeDaysBeforeEnd(newDate.toISOString())
        setOpenConfirmation(false)
        setDisabledBtn(false)
    }
  }

  const fetchCycleJourneysParticipantsDetails = async(idParticipant) => {
    const { data, error } = await serviceCycleJourneysParticipants.getJourneysParticipants({
      pageSize: 1000,
      pageNumber: 0,
      globalSearch: '',
      search: [
        {
            searchField: 'idParticipant',
            searchMode: 'equalTo',
            searchValue: idParticipant
        }
      ]
    });
    if (!error) {
      return data;
    }
  }

  const fetchCycleJourneyDetails = async(idCycleJourney) => {
    const { data, error } = await serviceCycleJourneys.getCycleJourneyDetails(idCycleJourney);
    if (!error) {
      return data;
    }
  }

  const fetchIncidences = async() => {
    const { data, error } = await serviceIncidents.getIncidentsList({
      pageSize: 1000,
      pageNumber: 0,
      globalSearch: '',
      search: [
        {
            searchField: 'idCycle',
            searchMode: 'equalTo',
            searchValue: idCycle
        },
        {
            SearchField: 'isOtherCompanyIncident',
            SearchMode: 'EqualTo',
            SearchValue: 0,
        }
      ],
      order: [],
  });
    if (!error) {
      setIncidences(data);
    }
  }

  // const updateJourney = async(dataJourney) => {
  //   const { data, error } = await serviceCycleJourneys.updateCycleJourney(dataJourney);
  // }

  const updateRemarksSeen = async(IdCycleJourney, mark) => {
    const { data, error } = await serviceCycleJourneys.updateRemarksSeen(IdCycleJourney, mark);
  }

  const organizeJourneys = (groupArray, cycleJourneys) => {
    const totalJourneys = cycleJourneys?.length;
  
    return groupArray.map(participant => {
      const journeys = [];
  
      cycleJourneys?.forEach((journey, index) => {
        journey?.cycleJourneyParticipants?.forEach(cycleJourneyParticipant => {
          if (cycleJourneyParticipant.idParticipant === participant.idParticipant) {
            // Agregar la jornada actual si participa en ella
            if (!journeys.some(j => j.day === index + 1)) {
              journeys.push({ day: index + 1, ...cycleJourneyParticipant });
            }
          }
        });
      });
  
      for (let i = 1; i <= totalJourneys; i++) {
        if (!journeys.some(j => j.day === i)) {
          journeys.push({ day: i, data: "" }); // Jornada vacía
        }
      }
  
      journeys.sort((a, b) => a.day - b.day);
  
      return {
        ...participant,  
        journeys         
      };
    });
  };

  //Funciones para ampliar plazo de un participante
  const handleOptions = (participant) => {
    setParticipantSelected(participant)
    setOpenEvOptions(true)
  }

  const extendEvaluationTime = async () => {
    setIsLoading(true);
    try {
        const updatedParticipant = {
            ...participantSelected,
            extraTimeLimit: extraTimeLimit,
        };
        await updateEvaluatorParticipant(updatedParticipant);
        setParticipantSelected(updatedParticipant);
    } catch (error) {
        console.error("Error al ampliar el evaluador:", error);
    } finally {
        setIsLoading(false);
    }
  };

  const updateEvaluatorParticipant = async (participant) => {
    const { data, error } = await serviceEvaluatorData.updateEvaluatorParticipant(participant);
    if (!error) {
      setOpenEvOptions(false)
      fetchCycleDetails(idCycle)
    }
  };

  //Función deshabilitar un participante del evaluador
  const openConfirmationDialog = (idEvaluatorParticipant) => {
    setIdEvaluatorParticipant(idEvaluatorParticipant);
    setOpenConfirmation(true)
  }

  const blockParticipantFromEvaluator = async(idEvaluatorParticipant) => {
    setDisabledBtn(true)
    const { data, error } = await serviceEvaluatorData.deleteEvaluatorParticipant(idEvaluatorParticipant);
    if (!error) {
      setIdEvaluatorParticipant('')
      fetchCycleDetails(idCycle)
    }
  }

  const journeyRemarksCreate = () =>{
    let remarkLanguage = t('CYCLES.JOURNEYS.NO_JOURNEY_REMARKS')
    //const tempRemarks = journey?.cycleJourneyRemarks ? journey.cycleJourneyRemarks : remarkLanguage;
    /*
        21-10-2024 - 0000213: POST IT Digital - Fechas de muestra
        OW requiere que los postIT Digitales tengan un comportamiento diferente.

        El postIt se mostrará desde la fecha de finalización de la jornada anterior 
        (siempre y cuando no sea la primera jornada) y hasta la fecha de finalización de la jornada actual
        (en ese momento pasaría a mostrarse la siguiente).
    */
    let tempRemarks = "";
    let now = new Date();
    let journeyDateTo = new Date(journey?.cycleJourneyDateTo); 
    if (journeyDateTo < now) {
        journeyDateTo = new Date(getNextJourney(cycle)?.cycleJourneyDateTo);
        if (journeyDateTo > now) {
            tempRemarks += getNextJourney(cycle)?.cycleJourneyRemarks;
        } else {
            tempRemarks = null
        }
    } else {
        tempRemarks += journey?.cycleJourneyRemarks;
    }
    tempRemarks = (tempRemarks !== null && tempRemarks !== "null") ? tempRemarks: remarkLanguage
    setJourneyRemarks(tempRemarks);

    if (journey && !journey?.remarksSeen && tempRemarks !== remarkLanguage) {
      setOpenRemarks(true)
    }
  }

  const mergeAssistants = (array1, array2) => {    
    const sortArray = (array) => {
        return array.sort((a, b) => {
            const nicknameA = a.participantNickName ? a.participantNickName.toLowerCase() : "";
            const nicknameB = b.participantNickName ? b.participantNickName.toLowerCase() : "";

            if (nicknameA < nicknameB) return -1;
            if (nicknameA > nicknameB) return 1;
            return 0;
        });
    };

    const sortedArray1 = sortArray(array1);
    const sortedArray2 = sortArray(array2);

    // Concatenar los dos arrays ordenados
    const mergedArray = [...sortedArray1, ...sortedArray2];

    return mergedArray;
  };

  const getValuationbyGroup = (group) => {
    var total = 0;
    //He modificado esta función para que de las valoraciones únicamente del journey en el que estamos.     
    journey?.cycleJourneyParticipants?.forEach((cycleJourneyParticipant) => {
      var rate = getParticipantRate(journey.idCycleJourney, cycleJourneyParticipant.idParticipant);
      if (rate === "") rate = -1;

      if ((group === 1) && (rate >= 9)) { total++; }
      if ((group === 2) && (rate >= 7) && (rate < 9)) { total++; }
      if ((group === 3) && (rate >= 5) && (rate < 7)) { total++; }
      if ((group === 4) && (rate >= 3) && (rate < 5)) { total++; }
      if ((group === 5) && (rate < 3) && (rate >= 0)) { total++; }
    });
    return total;
  }

  const getParticipantRate = (idCycleJourney, idParticipant) => {
       
    const cycleJourneyfiltered = cycle.cycleJourneys.filter(cycleJourney => cycleJourney.idCycleJourney ===idCycleJourney); 
    
    if (cycleJourneyfiltered.length> 0) {
        const participant = cycleJourneyfiltered[0].cycleJourneyParticipants.filter(cycleJourneyParticipant => cycleJourneyParticipant.idParticipant ===idParticipant);
      if (participant.length > 0) {  
        const rate = participant[0].cycleJourneyParticipantRate; 
       
        if (participant[0].idCycleJourneySER !== null) {
          return "R";
        }
  
        if (!participant[0].cycleJourneyAssistance) {
          if (isValidRate(rate) ) {
            return rate > 0 ? rate : "";
          } else {
            return "X";
          }
        } else {
          return rate > 0 ? rate : "";
        }
      } else {
        return 'X';
      }
    }
    else {
      return null;
    }
  }

  const isJourneyParticipant = (idCycleJourney, idParticipant) => {
    var cyclefiltered = cycle.cycleJourneys.filter(cycleJourney => cycleJourney.idCycleJourney === idCycleJourney);
    if (cyclefiltered.length > 0) {
      var participant = cyclefiltered[0].cycleJourneyParticipants.filter(cycleJourneyParticipant => cycleJourneyParticipant.idParticipant === idParticipant);
      if (participant.length == 0 && cyclefiltered[0].cycleJourneyPrivate === true ) {
        return false;
      } else if (participant.length > 0){
        return true;
      }
    }
    else {
      return false;
    }
  }

  const getJourneyParticipant = (idCycleJourney, idParticipant) => {
    var cyclefiltered = cycle.cycleJourneys.filter(cycleJourney => cycleJourney.idCycleJourney === idCycleJourney);
    if (cyclefiltered.length > 0) {
      var participant = cyclefiltered[0].cycleJourneyParticipants.filter(cycleJourneyParticipant => cycleJourneyParticipant.idParticipant === idParticipant);
      return participant[0];
    }
    else {
      return null;
    }
  }

  const borderColorCSS = (idCycleJourney, idParticipant) => {
    let rate = getParticipantRate(idCycleJourney, idParticipant);
    const incidenceClicked = incidences.filter(incidence => incidence.idCycleJourney === idCycleJourney && incidence.idParticipant === idParticipant);
    const participant = totalAssistants.find(participant => participant.idParticipant === idParticipant);
    if (rate == 'X' && participant.idCycleOrigin === null) {
      return 'red';
      //TODO hay que ver no ha realizado la jornada en este grupo
      // } else if (rate == '-') {
      //   return 'white'
    } else if(incidenceClicked !== undefined && incidenceClicked.length > 0) {
      return 'red';
    }else {
      return 'rgba(0, 133, 180, 0.71)';
    }
  }

  const backgroundColorCSS = (idCycleJourney, idParticipant) => {
    let rate = getParticipantRate(idCycleJourney, idParticipant);
    // const participantT = totalAssistants.find(participant => participant.idParticipant === idParticipant);
    const journeyTmp = cycle.cycleJourneys.find(journey => journey.idCycleJourney === idCycleJourney);
    const journeyParticipantsTmp = journeyTmp.cycleJourneyParticipants.find(participant => participant.idParticipant === idParticipant);
    const participantAssist = journeyParticipantsTmp.cycleJourneyAssistance;
        
    if (rate == 'R') {
      return 'orange';
    } else if(!participantAssist && isValidRate(rate)) {
      return 'lightgreen';
    } else {
      return 'rgb(0 133 180 / 41%)';
    }
  }

  function isValidRate(rate) {
    return typeof rate === 'number' && rate >= 0;
  }

  const getJourneyAvgRate = (journeyIndex) => {
    if (journeyIndex <= cycle.cycleJourneys.length) {
      var rates = cycle.cycleJourneys[journeyIndex-1].cycleJourneyParticipants.filter(x => x.cycleJourneyParticipantRate > 0 && x.cycleJourneyAssistance);
      if (rates.length > 0) {
        var sumrates = 0;
        rates.map((val, i) => {
          sumrates = sumrates + val.cycleJourneyParticipantRate;
        });
        return (sumrates / rates.length).toFixed(1);
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  }

  const handleAttendance = (idParticipant, idCycleJourneysParticipants, attendance) => {
    if (attendance) {
        setIdParticipant(idParticipant);
        setIdCycleJourneysParticipants(idCycleJourneysParticipants);
        setOpenDialog(true);
    } else {
        setLoading(true);
        serviceCycleJourneysParticipants.deleteCycleJourneyParticipant(idCycleJourneysParticipants).then(response => {
            if (!response.error) {
                fetchCycleDetails(idCycle);
                fetchIncidences();
            }
            setLoading(false);
        })
    }
  }

  const handleAttendanceDelete = (idParticipant, idCycleJourneysParticipants) => {
    setLoading(true);
    var data = { idCycleJourney: journey.idCycleJourney, idParticipant: idParticipant, idCycleJourneysParticipants: idCycleJourneysParticipants, CycleJourneyAssistance: true};
    serviceCycleJourneysParticipants.updateCycleJourneParticipant(data).then(response => {
      if (!response.error) {
        fetchCycleDetails(idCycle);
        fetchIncidences();
        setForceUpdate(prev => prev + 1); // Fuerza la actualización
      }
      setOpenDialog(false);
      setLoading(false);
    })
  }

  const handleActivate = () => {
    journey.cycleTarget = true;
    serviceCycles.updateCycleJourneys(journey).then(response => {
      if (!response.error) {
        fetchCycleDetails(idCycle);
      } else {
        journey.cycleTarget = false;
      }
    })
    setReloadKey(Math.random())
  }

  const handleActivateAssessment = () => {
    const newActiveStatus = !journey.activeAssesment;  // Invertimos el estado actual
    journey.activeAssesment = newActiveStatus;

    serviceCycles.updateCycleJourneys(journey).then(response => {
        if (!response.error) {
            fetchCycleDetails(idCycle);
        } else {
            journey.activeAssesment = !newActiveStatus;  // Revertimos si hay error
        }
    });

    setReloadKey(Math.random());
  }


  const getReportEvaluator = (participant) => {
    setOpenLoading(true);
    const file = {
      idEvaluatorParticipant: participant.idEvaluatorParticipant,
      fileName: participant.participantName + " " + participant.participantSurname + " " + t("LABEL.REPORT"),
      fileType: "participant"
    };

    serviceFiles.getPdfEvaluator(file)
      .then(() => setOpenLoading(false))
      .catch(error => {
        setOpenLoading(false);
        snackActions.error(t('EVALUATOR.ERROR_REPORT'));
      });
  };

  const handleExtraTime = (e) => {
    setExtraTimeLimit(e.target.value)
  }

  // Funciones para ver incidencias y editarlas
  const handleChange = (e) => {
    setIncidenceSelected({
        ...incidenceSelected,
        [e.target.name]: e.target.value
    });
  };

  const handleSubmit = type => {
    switch (type) {
        case 'NEW':
            serviceIncidents.newIncident(incidenceSelected).then(handleResponse)
            break
        case 'UPDATE':
            serviceIncidents.updateIncident(incidenceSelected).then(handleResponse)
            break
        default:
            break
    }
  }

  const handleResponse = response => {
    if (!response.error) {
        setOpenDialogIncidence(false)
        fetchIncidences();
        setForceUpdate(prev => prev + 1); // Fuerza la actualización del tooltip
    }
  }

  const deleteIncident = async () => {
    const incidentId = incidenceSelected.idIncident;

    if (!incidentId) {
        snackActions.error(t("INCIDENT.DELETE_ERROR_ID_NULL"));
        return;
    }

    try {
        const response = await serviceIncidents.deleteIncident({ idIncident: incidentId });

        if (!response.error) {
            setOpenDeleteConfirmDialog(false);
            setOpenDialogIncidence(false);
            handleForceUpdate();
            fetchIncidences();
        } else {
            snackActions.error(t("INCIDENT.DELETE_ERROR"));
        }
    } catch (error) {
        snackActions.error(t("INCIDENT.DELETE_ERROR"));
        console.error("Error deleting incident:", error);
    }
  };

  const handleForceUpdate = () => {
    setForceUpdate(prev => prev + 1);
  };

  const handleIncidence = (idCycleJourney, idParticipant) => {
    const incidenceClicked = incidences.filter(incidence => incidence.idCycleJourney === idCycleJourney && incidence.idParticipant === idParticipant);
    if (incidenceClicked !== undefined && incidenceClicked.length > 0) {
      setIncidenceSelected(incidenceClicked[0]);
      setOpenDialogIncidence(true);
    } else {
      setIncidenceSelected({
        descriptionIncident: '',
        idCycle: Number(idCycle),
        idCycleJourney: idCycleJourney,
        idParticipant: idParticipant,
        idUser: user.idUser,
        dateIncident: new Date().toISOString().slice(0, 19)
    });
      setOpenDialogIncidence(true);
    }
  }
  
  const getRemarksByIdCycleJourney = (idParticipant, idCycleJourney) => {
    if (Array.isArray(totalAssistants)) {
      const participantData = totalAssistants.find(assistant => assistant.idParticipant === idParticipant);
  
      if (participantData) {
        if (participantData.cycleParticipantRemarks && participantData.cycleParticipantRemarks !== null) {
          setCycleParticipantRemark(participantData.cycleParticipantRemarks);
        } else {
          setCycleParticipantRemark(t("TEXT.NO_RATE_CYCLE"));
        }
  
        if (Array.isArray(participantData.journeys)) {
          participantData.journeys.forEach(journey => {
            if (journey.idCycleJourney === idCycleJourney) {
              if (journey.cycleJourneyParticipantRemarks && journey.cycleJourneyParticipantRemarks !== null) {
                setCurrentParticipantRemark(journey.cycleJourneyParticipantRemarks);
              } else {
                setCurrentParticipantRemark(t("TEXT.NO_RATE_JOURNEY"));
              }
              setOpenParticipantRemark(true);
            }
          });
        } else {
          console.error(`Error: El participante con id ${idParticipant} no tiene journeys o no existe.`);
        }
  
      } else {
        console.error(`Error: El participante con id ${idParticipant} no existe en totalAssistantsShown.`);
      }
    } else {
      console.error("Error: totalAssistantsShown no es un array.");
    }
    return null;
  };
  

  const getParticipantsWithAssistance = (cycleJourney) => {
    // Definir la fecha de referencia (por ejemplo, siempre journey.cycleJourneyDateFrom)
    const referenceDate = new Date(journey?.cycleJourneyDateFrom);  
  
    // Convertir la fecha del ciclo a un objeto Date
    const cycleJourneyDateFrom = new Date(cycleJourney?.cycleJourneyDateFrom);
  
    // Comparar fechas y contar participantes con asistencia
    if (cycleJourneyDateFrom <= referenceDate) {
      return cycleJourney.cycleJourneyParticipants.filter(
        participant => participant.cycleJourneyAssistance === true
      ).length;
    }
    // Si no cumple la condición, retornar '-'
    return '-';
  }

  return (
    <div style={{width: '100%', maxWidth: '100%', padding: '0px 8px', overflowX: 'hidden'}}>
      <DialogC
        title={t("TEXT.LOADING")} 
        open={openLoading}
        hasCloseBtn={false}
        content={
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Box>
              <CircularProgress />
            </Box>
            <br />
            <Box>
              {t("EVALUATOR.LOADING_DATA")}
            </Box>
          </div>
        }
      />
      <DialogC
        open={openDialog}
        hasCloseBtn={false}
        height={200}
        title={t('CONFIRM.DELETE_INCIDENT')}
        subtitle={t('CONFIRM.DELETE_INCIDENT_SUBTITLE')}
        close={() => setOpenDialog(false)}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
                color='error'>
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button variant='contained' onClick={() => handleAttendanceDelete(idParticipant, idCycleJourneysParticipants)}>
              {t('BUTTON.DELETE')}
            </Button>
          </Box>
        }
      />
      {/*Cambia a Modal el Post-It para poder aplicarle estilos más fácilmente*/}
      <Modal
        open={openRemarks}
        onClose={() => setOpenRemarks(false)}
        aria-labelledby="post-it-title"
        aria-describedby="post-it-description"
      >
        <Box sx={style}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography id="post-it-title" variant="h4" component="h3">
              {t('LABEL.POSTIT')}
            </Typography>
            {/* <IconButton
              onClick={() => setOpenRemarks(false)}
              sx={{
                position: 'absolute',
                right: 16,
                top: 16,
              }}
            >
              <CloseIcon />
            </IconButton> */}
          </div>
          <Typography id="post-it-description" variant="h5" style={{ marginTop: '16px', color: '#0085b4' }}>
            <span dangerouslySetInnerHTML={{ __html: journeyRemarks }} />
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}>
            <Button
             variant='contained'
             onClick={() => {
              updateRemarksSeen(journey.idCycleJourney, false)
              setOpenRemarks(false)
             }}
             color='success'
             sx={{ mr: 1 }}
            >
              {t('BUTTON.VIEW_AGAIN')}
            </Button>
            <Button
            variant='contained'
            onClick={() => {
              updateRemarksSeen(journey.idCycleJourney, true)
              setOpenRemarks(false)
            }}
            color='error'
            >
              {t('BUTTON.CLOSE')}
            </Button>
          </div>
        </Box>
      </Modal>

      <DialogC
        open={openConfirmation}
        hasCloseBtn={false}
        height={230}
        title={t('CONFIRM.DELETE_EVALUATOR_PARTICIPANT')}
        subtitle={t('CONFIRM.DELETE_EVALUATOR_PARTICIPANT_SUBTITLE')}
        close={() => setOpenConfirmation(false)}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenConfirmation(false)}
                color='error'>
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button variant='contained' onClick={() => blockParticipantFromEvaluator(idEvaluatorParticipant)} disabled={disabledBtn}>
              {t('BUTTON.DELETE')}
            </Button>
          </Box>
        }
      />

      <DialogC //Tabla resumen de participación del evaluador
        fullScreen={true}
        open={openEvaluatorResults}
        hasCloseBtn={true}
        //height={'100%'}
        subtitle={
          '<p style="text-align:center; font-size:19px; margin-top:10px">'+
          t('EVALUATOR.EV_DIALOG_TITLE')
          +'</p>'
        }
        
        close={() => setOpenEvaluatorResults(false)}
        actions={
          <Grid container spacing={0} sx={{height:'100vh'}} className={classes.outletZoneConsultores}>
            <Grid item xs={12} className={classes.padding0} display={'flex'} flexDirection={'column'} alignItems={'center'}>
              {participants.map((participant, index) => {
                  // console.log(Date.parse(participant.evaluatorDateEnd));
                  // console.log(Date.parse(now));
                const participantRate = getParticipantRate(journey.idCycleJourney, participant.idParticipant);
                const date = new Date()
                let finished = date >= Date.parse(threeDaysBeforeEnd)
                let extended = participant.extraTimeLimit === 0 || participant.extraTimeLimit === null ? false : true;
                let evComplete = (participant.totalReceiverResponses === participant.numReceivers && participant.totalReceiverResponses > 0) || participant.totalReceiverResponses >= 4
                  ? 'full'
                  : participant.totalReceiverResponses < participant.numReceivers && (0 > participant.totalReceiverResponses < 4) && participant.isAutoevalDone == 1
                    ? 'half'
                    : 'empty'
              
                // Verificar si hay un extraTimeLimit y si ha pasado el tiempo adicional
                const extraTimeEndDate = new Date(threeDaysBeforeEnd);  // Fecha base de la evaluación
                extraTimeEndDate.setDate(extraTimeEndDate.getDate() + (participant.extraTimeLimit || 0));  // Sumar extraTimeLimit (días adicionales)
                
                const isExtraTimeActive = new Date() < extraTimeEndDate;  // Verificar si el tiempo extra sigue activo
                
                // La condición para deshabilitar el botón: si la evaluación no está completa, el tiempo extra sigue activo, y no se cumple la excepción de numReceivers === totalReceiverResponses
                const isArticleButtonDisabled = !(participant.numReceivers === participant.totalReceiverResponses) && (evComplete !== 'full' || isExtraTimeActive);

                return (
                  <Card key={`${idParticipant}+'-'+${index}`} variant="outlined" style={{ marginBottom: '0px', width: '100%' }}>
                    <Grid container spacing={1} padding={1} display="flex" alignItems="center" marginBottom={-2}>
                      <Grid item xs={2} display="flex" justifyContent="center">
                        <Avatar
                            sx={{ width: '55px', height: '55px', marginTop: '0.5em' }}
                            src={participantImages[participant.idParticipant]}
                        />
                      </Grid>
                      <Grid item xs={8.6} display='flex' justifyContent='space-between' alignItems='flex-end'>
                        <Box style={{ backgroundColor: 'white', color: '#0085B4', borderRadius: '5px', marginTop: '0px', lineHeight: '1.5em' }} padding={0}>
                          <strong>{participant.participantNickName?.toUpperCase()}</strong> - <span style={{color: '#6E6F6F'}}>{participant.participantName} {participant?.participantSurname}</span><br />
                          <Typography sx={{color:'#6E6F6F', lineHeight: '1.2em'}}>
                            {participant.participantPosition?.toUpperCase()}<br />
                            {participant.companyName?.toUpperCase()}
                          </Typography>
                        </Box>
                      </Grid>
                      {cycle.hasEvaluator && participant.idCycleParticipant && (
                        <Grid item xs={1} alignSelf={'flex-end'} position={'relative'}>
                          <EvButton
                            type={2}
                            size='2.5em'
                            color={finished ? 'white !important' : '#0085B4'}
                            background={
                              finished && participant.idEvaluatorParticipant
                                ? evComplete
                                : 'white'}
                            borderColor={finished ? evComplete : '#D1E9F1'}
                            disabled={!cycle.hasEvaluator || !participant.idEvaluatorParticipant}
                            // Ampliación de fecha de evaluador en Tabla resumen
                            onClick={
                              (e) => {
                                const date = new Date()
                                if (participant.idEvaluatorParticipant && !finished) {
                                  openConfirmationDialog(participant.idEvaluatorParticipant)
                                } else if (finished) {
                                  setEvCompleteState(evComplete)
                                  handleOptions(participant)
                                  // setOpenEvOptions(true)
                                } else {
                                  return false
                                }
                              }
                            }
                            displayX={!participant.idEvaluatorParticipant}
                            dateEnd={threeDaysBeforeEnd}
                            extended={extended}
                            participant={participant}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={1} padding={1} mt={1} display={'flex'} alignItems={'center'}>
                      <Grid item xs={2} style={{ textAlign: 'center', marginTop:'-0.5em' }}>
                        {/* Aquí iría un desplegable con los tipos de medidores  */}
                      </Grid>

                      <Grid container spacing={1} alignItems="center" justifyContent="space-around" flexWrap='noWrap' sx={{ width: '100%' }}>

                          <Grid item>
                            <Grid item>
                                <Typography 
                                  variant='caption' 
                                  sx={{ 
                                    textTransform: 'uppercase', 
                                    textAlign: 'center', 
                                    display: 'block' 
                                  }}
                                >
                                  {/* Cambiar el texto según el tamaño de la pantalla */}
                                  <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                                    {t('LABEL.COLLABOR.')}
                                  </Box>
                                  <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                    {t('LABEL.COLLABORATORS')}
                                  </Box>
                                </Typography>
                            </Grid>
                            <Grid container alignItems="center" justifyContent="center" flexWrap='noWrap' >
                              {/* Cambio de "0 de 0" por "- de -" */}
                              {participant.totalReceiverResponses === 0 && participant.numReceivers === 0 ? (
                                <>
                                  <Box style={{
                                      backgroundColor: 'white',
                                      color: 'black',
                                      border: '2px solid #D1E9F1',
                                      borderRadius: '5px',
                                      height: '2.6em',
                                      width: '1.6em',
                                      textAlign: 'center',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}>
                                    <strong style={{ fontSize: '1.4em', marginTop:'-5px' }}>-</strong>
                                  </Box>
                                  <Typography>{t('LABEL.OF')}</Typography>
                                  <Box style={{
                                      backgroundColor: 'white',
                                      color: 'black',
                                      border: '2px solid #D1E9F1',
                                      borderRadius: '5px',
                                      height: '2.6em',
                                      width: '1.6em',
                                      textAlign: 'center',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <strong style={{ fontSize: '1.4em', marginTop:'-5px' }}>-</strong>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box
                                    style={{
                                      backgroundColor: 'white',
                                      color: 'black',
                                      border: '2px solid #D1E9F1',
                                      borderRadius: '5px',
                                      height: '2.6em',
                                      width: '1.6em',
                                      textAlign: 'center',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <strong style={{ fontSize: '1.4em' }}>{participant.totalReceiverResponses}</strong>
                                  </Box>
                                  <Typography>{t('LABEL.OF')}</Typography>
                                  <Box style={{
                                      backgroundColor: 'white',
                                      color: 'black',
                                      border: '2px solid #D1E9F1',
                                      borderRadius: '5px',
                                      height: '2.6em',
                                      width: '1.6em',
                                      textAlign: 'center',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}>
                                    <strong style={{ fontSize: '1.4em' }}>{participant.numReceivers}</strong>
                                  </Box>
                                </>
                              )}
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Typography variant='caption' sx={{ textTransform: 'uppercase', textAlign: 'center', display: 'block' }}>
                              {t('LABEL.GRADE_AUTO')}
                            </Typography>
                            <Grid container alignItems="center" justifyContent="center">
                              <Box style={{
                                backgroundColor: '#FFE7C3',
                                border: '2px solid #FFE7C3',
                                borderRadius: '5px',
                                height: '2.6em',
                                width: '2.3em',
                                textAlign: 'center',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "bold"
                              }}>
                                <strong style={{fontSize: '1.3em'}}>
                                  {participant.nota_AutoEval ? parseFloat(participant.nota_AutoEval.toFixed(1)) : '-'}
                                </strong>
                              </Box>
                            </Grid>
                          </Grid>

                          <Grid item>
                            <Typography variant='caption' sx={{ textTransform: 'uppercase', textAlign: 'center', display: 'block' }}>
                              {t('EVALUATOR.NOTE')}
                            </Typography>
                            <Grid container alignItems="center" justifyContent="center">
                              <Box style={{
                                backgroundColor: '#72B8D4',
                                color: 'black',
                                border: '2px solid #72B8D4',
                                borderRadius: '5px',
                                height: '2.6em',
                                width: '2.3em',
                                textAlign: 'center',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                                <strong style={{ fontSize: '1.3em' }}>
                                  {participant.nota_Total ? parseFloat(participant.nota_Total.toFixed(1)) : '-'}
                                </strong>
                              </Box>
                            </Grid>
                          </Grid>

                          <Grid item>
                            <Typography variant='caption' sx={{ textTransform: 'uppercase', textAlign: 'center', display: 'block' }}>
                              {t('LABEL.QTR')}
                            </Typography>
                            <Grid container alignItems="center" justifyContent="center">
                              <Box style={{
                                backgroundColor: '#72B8D4',
                                border: '2px solid #72B8D4',
                                borderRadius: '5px',
                                height: '2.6em',
                                width: '2.3em',
                                textAlign: 'center',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                                <strong style={{ fontSize: '1.3em' }}>
                                  {participant.nota_QTR ? parseFloat(participant.nota_QTR.toFixed(1)) : '-'}
                                </strong>
                              </Box>
                            </Grid>
                          </Grid>

                          <Grid item>
                            <Typography variant='caption' sx={{ textTransform: 'uppercase', textAlign: 'center', display: 'block' }}>
                              {t('LABEL.AUTQTR')}
                            </Typography>
                            <Grid container alignItems="center" justifyContent="center">
                              <Box style={{
                                backgroundColor: '#FFE7C3',
                                border: '2px solid #FFE7C3',
                                borderRadius: '5px',
                                height: '2.6em',
                                width: '2.3em',
                                textAlign: 'center',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                                <strong style={{ fontSize: '1.3em' }}>
                                   {participant.nota_AutoQTR ? parseFloat(participant.nota_AutoQTR.toFixed(1)) : '-'}
                                </strong>
                              </Box>
                            </Grid>
                          </Grid>

                          <Grid item>
                            <Typography variant='caption' sx={{ textTransform: 'uppercase', textAlign: 'center', display: 'block' }}>
                              {t('LABEL.REPORT')}
                            </Typography>
                            <Grid container alignItems="center" justifyContent="center">
                              <Tooltip title={t('WORKSHEET.DOWNLOAD')}>
                                <Box style={{
                                  backgroundColor: isArticleButtonDisabled ? '#FFE7C3' : evComplete === 'full' ? '#FF9933' : '#FFE7C3',
                                  color: 'black',
                                  border: `2px solid ${isArticleButtonDisabled ? '#FFE7C3' : evComplete === 'full' ? '#FF9933' : '#FFE7C3'}`,
                                  borderRadius: '5px',
                                  height: '2.6em',
                                  width: '2.3em',
                                  textAlign: 'center',
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: 'pointer'
                                }}
                                onClick={() => {
                                  if (!isArticleButtonDisabled && participant?.numReceivers !== 0 && (participant?.totalReceiverResponses > 3 || (participant?.numReceivers < 4 && participant?.totalReceiverResponses === participant?.numReceivers))) {
                                    getReportEvaluator(participant)
                                  } else {
                                    return false
                                  }
                                }}>
                                  <Article sx={{ color: 'white !important', fontSize: '1.8em' }} />
                                </Box>
                              </Tooltip>
                            </Grid>
                          </Grid>

                      </Grid>                      

                   </Grid>
                  </Card>
                )
              })}
            </Grid>
            <Grid item xs={12} display={'flex'} justifyContent={'flex-end'} mt={1}>
              <Button
                variant='contained'
                onClick={() => setOpenEvaluatorResults(false)}
                sx={{height: '40px', width:'40px'}}
              >
                {t('BUTTON.OK')}
              </Button>
            </Grid>
          </Grid>
        }
      />

      <Dialog
        open={openEvOptions}
        fullWidth={true}
        onClose={() => setOpenEvOptions(false)}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          {t("EVALUATOR.EDIT.DAYS_ADED")}
        </DialogTitle>

        <DialogActions>
          <Grid container direction='column' alignItems='center' justifyContent='center'>
            <Grid item>
              <Grid container spacing={2} justifyContent='center'>
                <Grid item style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <Typography>{t('EVALUATOR.DAYS_ADED')}</Typography>
                </Grid>
                <Grid item xs={2.5} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <TextField
                    id="extraTimeLimit"
                    type="number"
                    size="small"
                    value={extraTimeLimit}
                    InputLabelProps={{shrink: true}}
                    InputProps={{ inputProps: { min: 0 } }} //No puede tener valor negativo
                    onChange={(e) => handleExtraTime(e)}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant='contained'
                    onClick={() => extendEvaluationTime()}
                    size='large'
                    color='primary'
                    style={{marginRight: '15px'}}
                    disabled={isLoading} // Botón deshabilitado mientras está cargando
                  >
                    {isLoading && (
                        <CircularProgress
                            size={20}
                            style={{color: 'white', marginRight: '10px'}}
                        />
                    )}
                    {t('BUTTON.MORE_TIME')}
                </Button>
                {
                      /*	0000243: Ampliación del plazo Medidor
                            ana.montes: He decidido que quitamos el botón de "informe"
                      */
                   /*evCompleteState === 'full' && (
                    <Button
                      variant='outlined'
                      onClick={() => getReportEvaluator(participantSelected)}
                      size={'large'}
                    >
                      {t('BUTTON.REPORTS')}
                    </Button>
                  )*/}
                </Grid>
              </Grid>
            </Grid>

            {/* Contenedor separado para el botón CLOSE, con margen superior */}
            <Grid item style={{width: '100%', marginTop: 30}}>
              <Grid container justifyContent="flex-end">
                <Button
                  variant="contained"
                  onClick={() => setOpenEvOptions(false)}
                  size="large"
                  color='error'
                  disabled={isLoading}
                >
                  {t('BUTTON.CLOSE')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>

        {/* Backdrop para loading tras darle al botón de "Ampliar" */}
        <Backdrop
          sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>

      <DialogC
        open={openDialogIncidence}
        width={500}
        hasCloseBtn={true}
        title={t('INCIDENT.NEW_INCIDENT')}
        close={() => setOpenDialogIncidence(false)}
        content={
            <TextField
                multiline
                fullWidth
                name='descriptionIncident'
                size='small'
                rows={5}
                inputProps={{maxLength: 255}}
                value={incidenceSelected?.descriptionIncident || ''}
                onChange={handleChange}
                //error={incidenceSelected?.descriptionIncident.length < 10} // Validación de longitud mínima
                //helperText={incidenceSelected?.descriptionIncident.length < 10 ? t('INCIDENT.DESCRIPTION.LENGHT') : ''}
            />
        }
        actions={
          <Box display='flex' alignItems='center'>
              {incidenceSelected?.idIncident && (
                <Button
                    variant='contained'
                    onClick={() => setOpenDeleteConfirmDialog(true)}
                    color='error'
                    sx={{marginRight: '10px'}}
                >
                    {t('BUTTON.DELETE')}
                </Button>
               )} 
              <Button
                variant='contained'
                onClick={() => {
                  if (incidenceSelected?.idIncident === null || incidenceSelected?.idIncident === undefined || !incidenceSelected.idIncident) {
                    handleSubmit('NEW')
                  } else {
                    handleSubmit('UPDATE')
                  }
                }}
                //disabled={incidenceSelected?.descriptionIncident.length < 10} // Deshabilita si la longitud es menor a 10
              >
                {incidenceSelected?.idIncident === null || incidenceSelected?.idIncident === undefined || !incidenceSelected.idIncident ? t('BUTTON.SAVE') : t('BUTTON.UPDATE')}
              </Button>
          </Box>
        }
      />

      <DialogC
          open={openDeleteConfirmDialog}
          onClose={() => setOpenDeleteConfirmDialog(false)}
          title={t('CONFIRM.TITLE_INCIDENT')}
          subtitle={t('CONFIRM.UNRECOVERY_DELETE2')}
          hasCloseBtn={false}
          actions={
              <Box display='flex' alignItems='center'>
                  <Box mr={1}>
                      <Button
                          variant='contained'
                          onClick={() => setOpenDeleteConfirmDialog(false)}
                          color='error'
                      >
                          {t('BUTTON.CANCEL')}
                      </Button>
                  </Box>
                  <Button
                      variant='contained'
                      onClick={deleteIncident} // Función que maneja la eliminación
                  >
                      {t('BUTTON.DELETE')}
                  </Button>
              </Box>
          }
      >
          {t('CONFIRM.UNRECOVERY_DELETE')}
      </DialogC>

      <DialogC
          open={openParticipantRemark}
          width={500}
          hasCloseBtn={true}
          subtitle={t('TEXT.RATE_JOURNEY2') +':'}
          close={() => setOpenParticipantRemark(false)}
          content={
            <Grid>
              <TextField
                  multiline
                  fullWidth
                  name='currentParticipantRemark'
                  size='small'
                  rows={5}
                  inputProps={{
                    maxLength: 255,
                    readOnly: true
                  }}
                  value={currentParticipantRemark || ''}
                  onChange={handleChange}
              />
              {cycle?.cycleJourneys?.length === journey?.cycleJourneyNumber && (
                <Grid pb={3}>
                  <Typography sx={{color:'#0085b4', lineHeight: '2em', marginTop: '1.5em', marginBottom: '1em'}}>
                    {t('TEXT.RATE_CYCLE')}
                  </Typography>
                  <TextField
                    multiline
                    fullWidth
                    name='cycleParticipantRemark'
                    size='small'
                    rows={5}
                    inputProps={{
                      maxLength: 255,
                      readOnly: true
                    }}
                    value={cycleParticipantRemark || ''}
                    onChange={handleChange}
                  />
                </Grid>
              )}
            </Grid>
          }
      />


      <Grid container spacing={1} padding={1} display="flex" alignItems="center">
        {journey && (
          <>
            <Grid item xs={8.5} md={9} sx={{ color: '#FF9933' }}>
                <h2 style={{width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap'}}>
                    {cycle?.cycleNameApp}
                    {cycle?.cycleTypeCode ? ` · ${cycle.cycleTypeCode}` : ''}
                    {getCurrentJourney(cycle).cycleJourneyNumber ? ` - ${getCurrentJourney(cycle).cycleJourneyNumber}D` : ''}
                </h2>
            </Grid>
            <Grid item xs={1.75} md={1.5} display="flex" alignItems='center' justifyContent="flex-start">
              <Link to={"/cycleimage/" + idCycle}>
                <CameraAlt sx={{ color: '#0085b4', fontSize: '2.8em' }}/>
              </Link>
            </Grid>
            <Grid item xs={1.75} md={1.5} display="flex" alignItems='center' justifyContent="flex-end">
              <Link to={"/cycledetails/" + idCycle}>
                <Info sx={{ color: '#0085b4', fontSize: '2.7em' }} />
              </Link>
            </Grid>
          </>
        )}
      </Grid>
      <br />

      {journey ?
        <>
          <Card variant="outlined" padding={1} style={{ fontSize:'22px', color: 'white', background: '#0085B4', padding: '10px' }}> {/*Esto afecta a lo que sale en el fondo azul: texto de Asistencia, Media, Valoración, y los nºs de Asistencia y Media*/}
            <Grid container spacing={1} padding={1} marginTop={0.5}>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Grid container columns={9} spacing={1}>
                  <Grid item xs={3} display="flex" justifyContent="center">{t('TEXT.ATTENDANCE')}</Grid>
                  <Grid item xs={1} padding={1} display="flex" justifyContent="center" style={{  border: '1px solid white' }}>
                    {getParticipantsWithAssistance(cycle.cycleJourneys[0])}
                  </Grid>
                  <Grid item xs={1} padding={1} display="flex" justifyContent="center" style={{  border: '1px solid white', marginLeft: '4px' }}>
                    {getParticipantsWithAssistance(cycle.cycleJourneys[1])}
                  </Grid>
                  <Grid item xs={1} padding={1} display="flex" justifyContent="center" style={{  border: '1px solid white', marginLeft: '4px' }}>
                    {getParticipantsWithAssistance(cycle.cycleJourneys[2])}
                  </Grid>
                  <Grid item xs={1} padding={1} display="flex" justifyContent="center" style={{  border: '1px solid white', marginLeft: '4px' }}>
                    {getParticipantsWithAssistance(cycle.cycleJourneys[3])}
                  </Grid>
                  <Grid item xs={1} padding={1} display="flex" justifyContent="center" style={{  border: '1px solid white', marginLeft: '4px' }}>
                    {getParticipantsWithAssistance(cycle.cycleJourneys[4])}
                  </Grid>

                  <Grid item xs={1}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Grid container columns={9} spacing={1}>
                  <Grid item xs={3} display="flex" justifyContent="center">{t('TEXT.AVERAGE')}</Grid>
                  <Grid item xs={1} padding={1} display="flex" justifyContent="center" style={{  border: '1px solid white' }}>
                    {getJourneyAvgRate(1)}
                  </Grid>
                  <Grid item xs={1} padding={1} display="flex" justifyContent="center" style={{  border: '1px solid white', marginLeft: '4px' }}>
                    {getJourneyAvgRate(2)}
                  </Grid>
                  <Grid item xs={1} padding={1} display="flex" justifyContent="center" style={{  border: '1px solid white', marginLeft: '4px' }}>
                    {getJourneyAvgRate(3)}
                  </Grid>
                  <Grid item xs={1} padding={1} display="flex" justifyContent="center" style={{  border: '1px solid white', marginLeft: '4px' }}>
                    {getJourneyAvgRate(4)}
                  </Grid>
                  <Grid item xs={1} padding={1} display="flex" justifyContent="center" style={{  border: '1px solid white', marginLeft: '4px' }}>
                    {getJourneyAvgRate(5)}
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Grid container columns={9} spacing={1}> {/* Esto afecta a los nºs de la Valoración */}
                  <Grid item xs={3} display="flex" justifyContent="center">{t('TEXT.VALUATION')}</Grid>
                  <Grid item xs={1} display="flex" flexDirection="column" style={{ alignItems: 'center', border: '1px solid white'}} sx={{justifyContent:'space-between', padding: '0px !important'}}>
                      <small style={{alignSelf: 'flex-start', color: 'yellow', fontSize: '11px', margin:'0.2em 0 0 0.2em'}}>A</small> <Typography sx={{fontSize:'22px', marginBottom: '0.2em', marginTop: '-0.5em'}}>{getValuationbyGroup(1)}</Typography>
                  </Grid>
                  <Grid item xs={1} display="flex" flexDirection="column" style={{ alignItems: 'center', border: '1px solid white' }} sx={{justifyContent:'space-between', padding: '0px !important', marginLeft: '4px' }}>
                      <small style={{alignSelf: 'flex-start', color: 'yellow', fontSize: '11px', margin:'0.2em 0 0 0.2em' }}>B</small> <Typography sx={{fontSize:'22px', marginBottom: '0.2em', marginTop: '-0.5em'}}>{getValuationbyGroup(2)}</Typography>
                  </Grid>
                  <Grid item xs={1} display="flex" flexDirection="column" style={{ alignItems: 'center', border: '1px solid white' }} sx={{justifyContent:'space-between', padding: '0px !important', marginLeft: '4px' }}>
                      <small style={{alignSelf: 'flex-start', color: 'yellow', fontSize: '11px', margin:'0.2em 0 0 0.2em' }}>C</small> <Typography sx={{fontSize:'22px', marginBottom: '0.2em', marginTop: '-0.5em'}}>{getValuationbyGroup(3)}</Typography>
                  </Grid>
                  <Grid item xs={1} display="flex" flexDirection="column" style={{ alignItems: 'center', border: '1px solid white' }} sx={{justifyContent:'space-between', padding: '0px !important', marginLeft: '4px' }}>
                      <small style={{alignSelf: 'flex-start',color: 'yellow', fontSize: '11px', margin:'0.2em 0 0 0.2em' }}>D</small> <Typography sx={{fontSize:'22px', marginBottom: '0.2em', marginTop: '-0.5em'}}>{getValuationbyGroup(4)}</Typography>
                  </Grid>
                  <Grid item xs={1} display="flex" flexDirection="column" style={{ alignItems: 'center', border: '1px solid white' }} sx={{justifyContent:'space-between', padding: '0px !important', marginLeft: '4px' }}>
                      <small style={{alignSelf: 'flex-start', color: 'yellow', fontSize: '11px', margin:'0.2em 0 0 0.2em' }}>E</small> <Typography sx={{fontSize:'22px', marginBottom: '0.2em', marginTop: '-0.5em'}}>{getValuationbyGroup(5)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>

          <Grid container spacing={1} padding={1} margin={0}>
            <Grid item xs={cycle.hasEvaluator ? 4 : 6} display="flex" justifyContent="center" sx={{alignContent: "center", padding: '0px !important', borderRight: '1px dashed lightgrey'}}>
              <Tooltip title={t('LABEL.POSTIT')}>
                <Button
                  onClick={() => {
                    setOpenRemarks(true);
                  }}>
                  <StickyNote2Icon  sx={{ color: '#FFC400', fontSize: '2.5em'}}/>
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={cycle.hasEvaluator ? 4 : 6} display="flex" justifyContent="center" sx={{alignContent: "center", padding: '0px !important'}}>
              <Tooltip title={t('TEXT.INCIDENT_CYCLE')}>
                <Link to={"/cycleincidents/" + idCycle + "/" + journey.idCycleJourney} style={{cursor: 'pointer'}}>
                  { incidences.length > 0 
                    ? <ReportProblemRoundedIcon sx={{color: 'red', fontSize: '2.5em'}} />
                    : <ReportProblemOutlinedIcon sx={{color: 'red', fontSize: '2.5em'}} /> 
                  }  
                </Link>
              </Tooltip>
            </Grid>
            {cycle.hasEvaluator && (
              <Grid item xs={4} display="flex" justifyContent="center" sx={{alignContent: "center", padding: '0px !important', borderLeft: '1px dashed lightgrey'}}>
                <Tooltip title={t('EVALUATOR.EV_DIALOG_TITLE')}>
                  <div>
                    <EvButton
                      type={1}
                      size='2.5em'
                      color='#0085B4'
                      background='white'
                      borderColor='#D1E9F1'
                      disabled={!cycle.hasEvaluator}
                      onClick={(e) => {
                        setOpenEvaluatorResults(true)
                      }}
                      displayX={false}
                      dateEnd={threeDaysBeforeEnd}
                      extended={false}
                    />
                  </div>
                </Tooltip>
              </Grid>
            )}
          </Grid>
          {
            (totalAssistants.map((participant, index) => {  
              // console.log(participant)         
              const date = new Date()
              let finished = date >= Date.parse(threeDaysBeforeEnd)
              let extended = participant.extraTimeLimit === 0 || participant.extraTimeLimit === null ? false : true;
              let evComplete = (participant.totalReceiverResponses === participant.numReceivers && participant.totalReceiverResponses > 0) || participant.totalReceiverResponses >= 4
                ? 'full'
                : participant.totalReceiverResponses < participant.numReceivers && (0 > participant.totalReceiverResponses < 4) && participant.isAutoevalDone == 1
                  ? 'half'
                  : 'empty'
              return(
                <Card 
                  key={`${idParticipant}+'-'+${index}`} 
                  variant="outlined" 
                  style={{ 
                    marginBottom: '10px', 
                    borderColor: participant.idCycleJourneyOrigin ? '#0085b4' : 'transparent', 
                    borderWidth: participant.idCycleJourneyOrigin ? '2px' : '0', 
                    borderStyle: participant.idCycleJourneyOrigin ? 'solid' : 'none'
                  }}
                >
                  <Grid container spacing={1} padding={1} display="flex" alignItems="center" marginBottom={-2}>
                    <Grid item xs={2} display="flex" justifyContent="center" onClick={() => getRemarksByIdCycleJourney(participant.idParticipant, journey.idCycleJourney)}>
                      <Avatar
                        sx={{ width: '55px', height: '55px', marginTop: '0.5em' }}
                        src={participantImages[participant.idParticipant]}
                      />
                    </Grid>
                    <Grid item xs={cycle.hasEvaluator ? 8.6 : 9.6} display='flex' justifyContent='space-between' alignItems='flex-end' onClick={() => getRemarksByIdCycleJourney(participant.idParticipant, journey.idCycleJourney)}>
                      <Box style={{ backgroundColor: 'white', color: '#0085B4', borderRadius: '5px', marginTop: '0px', lineHeight: '1.5em' }} padding={0}>
                        <strong style={{ fontSize: '1.2rem' }}>{participant.participantNickName?.toUpperCase()}</strong> - <span style={{color: '#6E6F6F', fontSize: '1.2rem'}}>{participant.participantName} {participant?.participantSurname}</span><br />
                        <Typography sx={{color:'#6E6F6F', lineHeight: '1.0em', fontSize: '1.2rem'}}>
                          {participant.participantPosition?.toUpperCase()}<br />
                          {participant.companyName?.toUpperCase()}
                        </Typography>
                      </Box>
                    </Grid>
                    {cycle.hasEvaluator && participant.idCycleParticipant && (
                      <Grid item xs={1} alignSelf={'flex-end'} position={'relative'}>
                        <EvButton
                          type={2}
                          size='2.5em'
                          color={finished ? 'white !important' : '#0085B4'}
                          background={
                            finished && participant.idEvaluatorParticipant
                              ? evComplete
                              : 'white'}
                          borderColor={finished ? evComplete : '#D1E9F1'}
                          disabled={!cycle.hasEvaluator || !participant.idEvaluatorParticipant}
                          // Quito de aquí la función de ampliación del plazo del medidor
                          onClick={
                            (e) => {
                              if (participant.idEvaluatorParticipant && !finished) {
                                openConfirmationDialog(participant.idEvaluatorParticipant)
                              } else {
                                return false
                              }
                            }
                          }
                          displayX={!participant.idEvaluatorParticipant}
                          dateEnd={threeDaysBeforeEnd}
                          extended={extended}
                          participant={participant}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={1} padding={1}>
                    <Grid item xs={2} style={{ textAlign: 'center', marginTop:'-0.5em' }}>
                      <Typography variant='caption' sx={{ fontSize: '0.8em' }}>{t('TEXT.ATTENDANCE')}</Typography>
                      {getJourneyParticipant(journey.idCycleJourney, participant.idParticipant)?.cycleJourneyAssistance === true ? (                        
                          <Link to={""} onClick={async () => {
                              const participantData = getJourneyParticipant(journey.idCycleJourney, participant.idParticipant);
                              const idParticipant = participantData?.idParticipant;
                              const idCycleJourney = journey.idCycleJourney;

                              // Verifica si tiene Targets asociados
                              const response = await serviceParticipantsTargets.getListByJourney(idParticipant, idCycleJourney);
                              const { data, error } = response;

                              if (error) {
                                  console.error("Error al obtener targets:", error);
                                  return;
                              }

                              const hasTargets = data && Array.isArray(data) && data.length > 0;

                              // console.log(`idParticipant en onClick: ${idParticipant}, idCycleJourney en onClick: ${idCycleJourney}, Tiene IdParticipantTarget asociados: ${hasTargets}`);

                              if (!hasTargets) {
                                  handleAttendance(idParticipant, participantData?.idCycleJourneysParticipants, false); // Quitar falta de asistencia
                              } else {
                                snackActions.error(t('CONFIRM.TARGETS_DETECTED'));     
                              }
                          }}>
                              <CheckBoxIcon sx={{ color: 'orange', verticalAlign: 'middle', fontSize: '3em' }} />
                          </Link>
                      ) : (
                          <Link to={""} onClick={() => {
                              const participantData = getJourneyParticipant(journey.idCycleJourney, participant.idParticipant);
                              const idParticipant = participantData?.idParticipant;

                              // Aquí se marca la asistencia
                              handleAttendance(idParticipant, participantData?.idCycleJourneysParticipants, true);
                          }}>
                              <DisabledByDefaultRoundedIcon sx={{ color: 'red', verticalAlign: 'middle', fontSize: '3em' }} />
                          </Link>
                      )}
                    </Grid>
                    <Grid item xs={10} container spacing={1} justifyContent="space-between" alignItems="center" >
                      <Grid item xs={9.5} container spacing={1} marginLeft={0.5}>
                        {
                          cycle.cycleJourneys.map((journey, i) =>                    
                          {
                            // const participantAssist = participant?.journeys?.find(journeyP => journeyP.idCycleJourney === journey.idCycleJourney).cycleJourneyAssistance;
                            if (isJourneyParticipant(journey.idCycleJourney, participant.idParticipant) === true) {
                              return (
                                <Grid key={journey.idCycleJourney} item xs={1.9} md display="flex" justifyContent="center" style={{ flexShrink: '0', marginRight: '0.4em' }}>
                                  
                                  {/*En el Tooltip se muestra la DescriptionIncident si la hay*/}
                                  <Tooltip 
                                    title={
                                      incidences.some(inc => inc.idCycleJourney === journey.idCycleJourney && inc.idParticipant === participant.idParticipant && inc.descriptionIncident)
                                        ? (
                                          <span>
                                            {
                                              incidences
                                                .find(inc => inc.idCycleJourney === journey.idCycleJourney && inc.idParticipant === participant.idParticipant).descriptionIncident
                                                .split('\n')
                                                .map((line, index) => (
                                                  <React.Fragment key={index}>
                                                    {line}
                                                    <br />
                                                  </React.Fragment>
                                                ))
                                            }
                                          </span>
                                        )
                                        : t('TEXT.NEW_INCIDENT_CYCLE') // Muestra el texto de nueva incidencia si no hay descripción
                                    }
                                  >
                                    <Box 
                                      style={{
                                        backgroundColor: backgroundColorCSS(journey.idCycleJourney, participant.idParticipant) === 'lightgreen' || backgroundColorCSS(journey.idCycleJourney, participant.idParticipant) === 'orange' ? backgroundColorCSS(journey.idCycleJourney, participant.idParticipant) : cycle.cycleJourneys.length - 1 === i ? 'white' : backgroundColorCSS(journey.idCycleJourney, participant.idParticipant),
                                        borderColor: borderColorCSS(journey.idCycleJourney, participant.idParticipant),
                                        borderStyle: 'solid',
                                        borderWidth: '3px',
                                        borderRadius: '5px',
                                        height: '2.5em',
                                        minWidth: '2.5em',
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        cursor: borderColorCSS(journey.idCycleJourney, participant.idParticipant) === 'red' ? 'pointer' : 'inherit'
                                      }}
                                      onClick={ () => handleIncidence(journey.idCycleJourney, participant.idParticipant)}
                                    >
                                      <strong style={{ color: getParticipantRate(journey.idCycleJourney, participant.idParticipant) < 7 ? 'red' : 'black', fontSize: '1.6em' }}>{getParticipantRate(journey.idCycleJourney, participant.idParticipant)}</strong> {/* Esto son los números de las casillas azules de cada participante*/}
                                      {/* <strong style={{ color: !participantAssist && getParticipantRate(journey.idCycleJourney, participant.idParticipant) !== 'X' ? 'green' : getParticipantRate(journey.idCycleJourney, participant.idParticipant) < 7 ? 'red' : 'black', fontSize: '1.6em' }}>{getParticipantRate(journey.idCycleJourney, participant.idParticipant)}</strong> Esto son los números de las casillas azules de cada participante */}
                                    </Box>
                                  </Tooltip>
                                </Grid>
                              )
                            } else {
                              return (
                                <Grid key={journey.idCycleJourney} item xs={1.9} md display="flex" justifyContent="center" style={{ flexShrink: '0', marginRight: '0.4em' }}>
                                  <Box 
                                    style={{
                                      backgroundColor: 'white',
                                      borderColor: '#0085B4',
                                      borderStyle: 'solid',
                                      borderWidth: '3px',
                                      borderRadius: '5px',
                                      height: '2.5em',
                                      minWidth: '2.5em',
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    //onClick={ (e) => console.log(journey)}
                                  >
                                  </Box>
                                </Grid>
                              )
                            }
                          }
                          )
                        }
                        <Grid item xs style={{borderTop: '2px solid #D1E9F1', transform: 'translateY(1.9em)', marginLeft:'0.3em'}}></Grid>
                      </Grid>
                      <Grid item xs={2} md display="flex" flexDirection="column" justifyContent="center" ml={0}>
                        <Box style={{
                          backgroundColor: 'white',
                          color: 'black',
                          border: '2px solid #D1E9F1',
                          borderRadius: '5px',
                          height: '2.5em',
                          width: '2.5em',
                          textAlign: 'center',
                          padding: '10px 0',
                          display:"flex",
                          justifyContent:"center",
                          alignItems:"center"
                        }}>
                          <strong style={{fontSize: '1.6em'}}>{participant.cycleParticipantRate}</strong> {/* Esto son los números de las casillas finales de cada participante*/}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              )
            })
            )}
          <Grid item xs={12} display="flex" justifyContent="center" marginTop={3} marginBottom={3}>
            <Grid item xs={8}>
              <Button
                sx={{ width: '100%', fontSize: '18px', fontWeight: 'bold' }}
                disabled={journey.cycleTarget === true}
                variant='contained'
                onClick={handleActivate}
              >
                {journey.cycleTarget === false
                  ?  t('BUTTON.ACTIVATE_TARGETS')
                  :  t('BUTTON.TARGETS_ACTIVATED')
                }
              </Button>
            </Grid>

            {/* BOTON PARA ACTIVAR O DESACTIVAR VALORACIÓN DEL PARTICIPANTE.
                A petición de OW se permite sólo en la última jornada*/}
            {journey.cycleJourneyNumber === cycle.cycleJourneys.length  && (
                <Grid item xs={8}>
                    <Button
                        sx={{ width: '100%', marginLeft: '20px', fontSize: '18px', fontWeight: 'bold' }}
                        disabled={journey.activeAssesment === true}
                        variant='contained'
                        onClick={handleActivateAssessment}
                    >
                        {journey.activeAssesment === false
                            ? t('BUTTON.ACTIVATE_ASSESSMENT')
                            : t('BUTTON.DEACTIVATE_ASSESSMENT')
                        }
                    </Button>
                </Grid>
            )}

          </Grid>
        </>
        :
        <Loading height='50vh' style={{zIndex: 9999999}}/>
      }
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Journey;